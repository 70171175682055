
import {
  ref,
  computed,
  onMounted,
  reactive,
  toRefs,
  defineComponent,
  nextTick,
  watch,
} from "vue";
import { ColumnProps } from "ant-design-vue/es/table/interface";
import { DownOutlined, UpOutlined } from "@ant-design/icons-vue";
import {
  getBrandsArrayList,
  getMakeArrayList,
  getPowerTrainArrayList,
  getVehicleiList,
  exportVANData,
} from "@/API/systemVehicle/systemVehicleIndex";
import SystemVehicleImportContent from "@/views/System/VehicleManagement/components/systemVehicleImportContent.vue";
import blobDownload from "@/utils/blobDownload";
import useTableHeight from "@/hooks/useTableHeight";
import Pagination from "@/components/Pagination.vue";
import { BrandsArrayType, VehicleType } from "./types";
import { columns } from "./tableColumns";
import { downloadFromStream } from "@/utils/payment/downloadFile";

export default defineComponent({
  components: {
    SystemVehicleImportContent,
    Pagination,
    DownOutlined,
    UpOutlined,
  },
  setup() {
    const { tableHeight } = useTableHeight();
    //查询框数据
    const searchParams = reactive<any>({
      brandId: "",
      vin: "",
      commissionNumber: "",
      dealerCode: "",
      dealerName: "",
      makeId: "",
      materialNo: "",
      modelName: "",
      modelYear: "",
      powerTrainId: "",
      retailDateFrom: "",
      retailDateTo: "",
      typeClass: "",
      wholesaleDateFrom: "",
      wholesaleDateTo: "",
    });

    const resetSearch = reactive<any>({
      brandId: "",
      vin: "",
      commissionNumber: "",
      dealerCode: "",
      dealerName: "",
      makeId: "",
      materialNo: "",
      modelName: "",
      modelYear: "",
      powerTrainId: "",
      retailDateFrom: "",
      retailDateTo: "",
      typeClass: "",
      wholesaleDateFrom: "",
      wholesaleDateTo: "",
    });

    const isOpen = ref(false);

    const isOpenSearch = () => {
      isOpen.value = !isOpen.value;
      nextTick(() => {
        try {
          let paginationHeight = 0;
          const clientHeight: number = document.body.clientHeight;
          const tableOffsetTop: number = (
            document.querySelector(".ant-table") as HTMLDivElement
          ).getBoundingClientRect().top;
          const tableTheadHeight: number = (
            document.querySelector(".ant-table-thead") as HTMLDivElement
          ).offsetHeight;
          paginationHeight = (
            document.querySelector(".pagination-container") as HTMLUListElement
          ).offsetHeight;
          tableHeight.value =
            clientHeight -
            tableOffsetTop -
            paginationHeight -
            tableTheadHeight -
            5;
        } catch (e) {
          console.log(e);
        }
      });
    };

    const wholesaleDateChange = (date: string[]): void => {
      searchParams.wholesaleDateFrom = date[0];
      searchParams.wholesaleDateTo = date[1];
    };

    const retailDateChange = (date: string[]): void => {
      searchParams.retailDateFrom = date[0];
      searchParams.retailDateTo = date[1];
    };

    const resetSearchClick = () => {
      searchParams.brandId = resetSearch.brandId;
      searchParams.vin = resetSearch.vin;
      searchParams.commissionNumber = resetSearch.commissionNumber;
      searchParams.dealerCode = resetSearch.dealerCode;
      searchParams.dealerName = resetSearch.dealerName;
      searchParams.makeId = resetSearch.makeId;
      searchParams.materialNo = resetSearch.materialNo;
      searchParams.modelName = resetSearch.modelName;
      searchParams.modelYear = resetSearch.modelYear;
      searchParams.powerTrainId = resetSearch.powerTrainId;
      searchParams.retailDateFrom = resetSearch.retailDateFrom;
      searchParams.retailDateTo = resetSearch.retailDateTo;
      searchParams.typeClass = resetSearch.typeClass;
      searchParams.wholesaleDateFrom = resetSearch.wholesaleDateFrom;
      searchParams.wholesaleDateTo = resetSearch.wholesaleDateTo;
    };

    //brandsArray的数据，从后端获取
    const brandsArray = ref<BrandsArrayType[]>([]);
    //获取brandsArray
    const getBrandsArray = () => {
      return getBrandsArrayList().then((res) => {
        for (let i = 0; i < res.length; i++) {
          brandsArray.value.push({
            key: res[i].id,
            value: res[i].brandNameEn,
          });
        }

        resetSearch.brandId = searchParams.brandId =
          (brandsArray.value[0] && brandsArray.value[0].key) || null;
      });
    };
    //makeArray的数据，从后端获取
    const makeArray = ref<BrandsArrayType[]>([]);
    //获取markArray
    const getMakeArray = () => {
      getMakeArrayList().then((res) => {
        for (let i = 0; i < res.length; i++) {
          makeArray.value.push({
            key: res[i],
            value: res[i],
          });
        }
        makeArray.value = res;
      });
    };
    //powerTrainArray
    const powerTrainArray = ref<BrandsArrayType[]>([]);
    //获取powerTrainArray
    const getPowerTrainArray = () => {
      getPowerTrainArrayList().then((res) => {
        powerTrainArray.value = res;
      });
    };

    //分页
    const pagination = reactive({
      total: 0,
      currentPage: 0,
      pageSize: 10,
    });

    //表格

    const dataSource = ref<VehicleType[]>([]);

    //表格高度
    // const tableHeight = window.innerHeight - 430;
    // const tableWidth = window.innerWidth
    //表格类型
    type Key = ColumnProps["key"];
    //抽屉事件
    const visible = ref(false);
    //导出表格
    const exportTable = () => {
      const { brandId, make, nstModelName, powerTrainId } = searchParams;
      blobDownload({
        url: `/vapi/vehicleModelInfo/exportExcel`,
        method: "get",
        params: {
          brandId,
          make,
          nstModelName,
          powerTrainId,
        },
      });
    };

    //获取表格数据
    const getTableData = () => {
      const params = {
        ...searchParams,
      };

      const pageParams = {
        page: pagination.currentPage,
        size: pagination.pageSize,
      };

      getVehicleiList(params, pageParams).then((res) => {
        pagination.total = res.totalElements;
        dataSource.value = [];
        for (let i = 0; i < res.content.length; i++) {
          const one = res.content[i];
          dataSource.value.push({
            commissionNumber: one.commissionNumber,
            vin: one.vin,
            materialNo: one.materialNo,
            modelNameEn: one.modelNameEn,
            modelNameCn: one.modelNameCn,
            modelYear: one.modelYear,
            engineNumber: one.engineNumber,
            vehicleUsageDescription: one.vehicleUsageDescription,
            wholesaleDate: one.wholesaleDate,
            retailDate: one.retailDate,
            vehicleMasterId: one.vehicleMasterId,
          });
        }
      });
    };

    //更改分页页签
    const pageChange = (page: number) => {
      pagination.currentPage = page - 1;

      getTableData();
    };
    //改变每页大小
    const sizeChange = (page: number, pageSize: number) => {
      pagination.pageSize = pageSize;
      pagination.currentPage = 0;
      getTableData();
    };

    //重新设置筛选信息
    //导入
    const importTable = (): void => {
      visible.value = !visible.value;
    };

    const isBrandVan = computed(() => {
      return searchParams.brandId === 5;
    });
    const exportShowFlag = ref(false);
    const hanlderSearchClick = (): void => {
      if (isBrandVan.value) exportShowFlag.value = true;
      pagination.currentPage = 0;
      pagination.pageSize = 10;
      getTableData();
    };

    watch(
      () => searchParams.brandId,
      (newId: any) => {
        if (newId !== 5) {
          exportShowFlag.value = false;
        }
      }
    );

    const init = async () => {
      getMakeArray();
      getPowerTrainArray();
      await getBrandsArray();
      getTableData();
    };

    onMounted(() => {
      init();
    });

    const handleExport = async () => {
      const res = await exportVANData({
        ...searchParams,
      });
      console.log('res', res);
      downloadFromStream(res);
    };

    return {
      isOpenSearch,
      isOpen,
      wholesaleDateChange,
      retailDateChange,
      visible,
      importTable,
      sizeChange,
      pageChange,
      exportTable,
      resetSearchClick,
      hanlderSearchClick,
      tableHeight,
      dataSource,
      columns,
      ...toRefs(pagination),
      ...toRefs(searchParams),
      brandsArray,
      makeArray,
      powerTrainArray,
      getTableData,
      handleExport,
      isBrandVan,
      exportShowFlag,
    };
  },
});
